import Dashboard from "views/Dashboard.js";
import Clinics from "views/Clinics";
import Gender from "views/Gender";
import Forecast from "views/Forecast";
import About from "views/About";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa-solid fa-gauge",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/clinics",
    name: "Clinics",
    icon: "fa-sharp fa-solid fa-house-chimney-medical",
    component: Clinics,
    layout: "/admin",
  },
  {
    path: "/gender",
    name: "Gender",
    icon: "fa-solid fa-venus-mars",
    component: Gender,
    layout: "/admin",
  },
  {
    path: "/forecast",
    name: "Forecast",
    icon: "fa-solid fa-brain",
    component: Forecast,
    layout: "/admin",
  },
  {
    path: "/about",
    name: "About",
    icon: "fa-solid fa-circle-info",
    component: About,
    layout: "/admin",
  }
];
export default routes;
