import { useState, memo } from "react";

import {
    Button,
    Row,
    Col,
} from "reactstrap";

import "./Selector.css";

function AppointmentSelector(props) {
    const [active1, setActive1] = useState("scheduled");
    const [active2, setActive2] = useState("show");

    const handleClick = (apointmentType) => {
        switch (apointmentType) {
            case "scheduled":
                props.setAttendance1("scheduled");
                setActive1("scheduled");
                break;
            case "cancelled":
                props.setAttendance1("cancelled");
                setActive1("cancelled");
                break;
            case "show":
                props.setAttendance2("show");
                setActive2("show");
                break;
            case "noshow":
                props.setAttendance2("no-show");
                setActive2("noshow");
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Row form>
                <Col xs={6} className="col-md-3">
                    <Button
                        block
                        className="btn-selector"
                        active={active1 === "scheduled" ? true : false}
                        onClick={() => handleClick("scheduled")}
                    >Scheduled</Button>
                </Col>
                <Col xs={6} className="col-md-3">
                    <Button
                        block
                        className="btn-selector"
                        active={active1 === "cancelled" ? true : false}
                        onClick={() => handleClick("cancelled")}
                    >Cancelled</Button>
                </Col>
                <Col xs={6} className="col-md-3">
                    <Button
                        block
                        className="btn-selector"
                        active={active2 === "show" ? true : false}
                        onClick={() => handleClick("show")}
                    >Show</Button>
                </Col>
                <Col xs={6} className="col-md-3">
                    <Button
                        block
                        className="btn-selector"
                        active={active2 === "noshow" ? true : false}
                        onClick={() => handleClick("noshow")}
                    >No-Show</Button>
                </Col>
            </Row>
        </>
    )
};

export default memo(AppointmentSelector);