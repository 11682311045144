const Show_NoShowChart = {
  data: (data) => {
    return {
      labels: [
        "Show",
        "No-Show"
      ],
      datasets: [
        {
          backgroundColor: ["#6bd098", "#fbc658"],
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: [0, 1, 1, 0],
          tension: 0.4,
          fill: true,
          data: data,
        }
      ]
    }
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  }
}

const Scheduled_CancelledChart = {
  data: (data) => {
    return {
      labels: [
        "Scheduled",
        "Cancelled"
      ],
      datasets: [
        {
          backgroundColor: ["#51cbce", "#ef8157"],
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: [0, 1, 1, 0],
          tension: 0.4,
          fill: true,
          data: data,
        }
      ]
    }
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  }
}

const Show_NoShowChartAndScheduled_CancelledChart = {
  data: (data1, data2) => {
    return {
      datasets: [
        {
          data: data1,
          backgroundColor: [
            "#ef8157",
            "#51cbce",
          ],
          label: "Show/No-Show"
        },
        {
          data: data2,
          backgroundColor: [
            "#fbc658",
            "#6bd098",
          ],
          label: "Scheduled/Cancelled"
        }
      ]
    }
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  }
}

const ProvidingTypeChart = {
  data: (data) => {
    return {
      labels: [
        "Questionare",
        "Agent"
      ],
      datasets: [
        {
          backgroundColor: ["#d3502f", "#3f51b5"],
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: [0, 1, 1, 0],
          tension: 0.4,
          fill: true,
          data: data,
        }
      ]
    }
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  }
}

const Verified_UnverifiedChart = {
  data: (data) => {
    return {
      labels: [
        "Verified",
        "Unverified"
      ],
      datasets: [
        {
          backgroundColor: ["#49d49d", "#8151bd"],
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: [0, 1, 1, 0],
          tension: 0.4,
          fill: true,
          data: data,
        }
      ]
    }
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  }
}

const PerGenderChart = {
  data: (data) => {
    return {
      labels: [
        "Male",
        "Female",
        "N-A"
      ],
      datasets: [
        {
          backgroundColor: ["#51bcda", "#ef8157", "#6c757d"],
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: [0, 1, 1, 0],
          tension: 0.4,
          fill: true,
          data: data,
        }
      ]
    }
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  }
}

const PerAgeChart = {
  data: (data) => {
    return {
      labels: [
        "Senior Adult",
        "Middle Age Adult",
        "Adult",
        "Teen",
        "Child",
        "Toddler",
        "Infant"
      ],
      datasets: [
        {
          backgroundColor: ["#343a40", "#6c757d", "#51cbce", "#51bcda", "#6bd098", "#fbc658", "#ef8157"],
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: [0, 1, 1, 0],
          tension: 0.4,
          fill: true,
          data: data,
        }
      ]
    }
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  }
}

const TimeseriesAppointments = {
  data: (data) => {
    return {
      datasets: [
        {
          backgroundColor: "#ef8157",
          // pointRadius: 0,
          // pointHoverRadius: 0,
          // borderWidth: [0, 1, 1, 0],
          // tension: 0.4,
          data: data,
        }
      ]
    }
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    }
  }
}

module.exports = {
  Show_NoShowChart,
  Scheduled_CancelledChart,
  Verified_UnverifiedChart,
  ProvidingTypeChart,
  PerGenderChart,
  PerAgeChart,
  Show_NoShowChartAndScheduled_CancelledChart,
  TimeseriesAppointments
};
