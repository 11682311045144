import { useState } from "react";
import useFetch from "use-http";
import { Doughnut, Line } from "react-chartjs-2";
import { Map, Marker, Overlay } from "pigeon-maps"

import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";

import {
    Show_NoShowChartAndScheduled_CancelledChart,
    TimeseriesAppointments,
  } from "variables/charts.js";

import { defaultStartingDate, todayDateToString, defaultCity, defaultCoords, defaultGender } from "variables/variables";

import Selector from "components/Selector/Selector";
import AppointmentSelector from "components/Selector/AppointmentSelector";

import "./Clinics.css";

const apiURL = process.env.REACT_APP_API_URL;

function Gender() {
    const [startDate, setStartDate] = useState(defaultStartingDate);
    const [endDate, setEndDate] = useState(todayDateToString);
    const [city, setCity] = useState(defaultCity);
    const [gender, setGender] = useState(defaultGender);

    const [attendance1, setAttendance1] = useState("all");
    const [attendance2, setAttendance2] = useState("show");

    let dependencies = [startDate, endDate, city, gender, attendance1, attendance2];

    const { data: stats = [] } = useFetch(`${apiURL}/stats_by_gender_by_city/${startDate}/${endDate}/${city}/${gender}/`, {}, dependencies);
    const { data: dates = [] } = useFetch(`${apiURL}/get_appointments_by_city_by_gender_dates/${startDate}/${endDate}/${city}/${gender}/${attendance1}/${attendance2}/`, {}, dependencies);
    const { data: locations = [] } = useFetch(`${apiURL}/get_appointments_per_locations/${startDate}/${endDate}/`, {}, dependencies);
    const { data: dataTypes = [] } = useFetch(`${apiURL}/get_data_type_by_gender_by_city/${startDate}/${endDate}/${city}/${gender}/`, {}, dependencies);

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Selector setStartDate={setStartDate} setEndDate={setEndDate} setCity={setCity} city={city} setGender={setGender} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                        <CardBody>
                            <Row>
                            <Col md="4" xs="5">
                                <div className="icon-big text-center icon-primary">
                                    <i className="fa-solid fa-calendar-days text-primary" />
                                </div>
                            </Col>
                            <Col md="8" xs="7">
                                <div className="numbers">
                                    <p className="card-category">Scheduled Appointments</p>
                                        <CardTitle tag="p">{stats.scheduled}</CardTitle>
                                    <p />
                                </div>
                            </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <hr />
                            <div className="stats">
                                <i className="fas fa-sync-alt" /> In Total
                            </div>
                        </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="fa-solid fa-calendar-days text-danger" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Cancelled Appointments</p>
                                                <CardTitle tag="p">{stats.cancelled}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-sync-alt" /> In Total
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-success">
                                        <i className="fa-solid fa-calendar-days text-success" />
                                    </div>
                                </Col>
                                <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Show Appointments</p>
                                            <CardTitle tag="p">{stats.show}</CardTitle>
                                        <p />
                                    </div>
                                </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-sync-alt" /> In Total
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="fa-solid fa-calendar-days text-warning" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">No-Show Appointments</p>
                                                <CardTitle tag="p">{stats.noshow}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-sync-alt" /> In Total
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody style={{ height: "28em" }}>
                                {(stats.show !== null & stats.noshow !== null & stats.scheduled !== null & stats.cancelled !== null) &&
                                    <Doughnut
                                        data={Show_NoShowChartAndScheduled_CancelledChart.data([stats.cancelled, stats.scheduled], [stats.noshow, stats.show])}
                                        options={Show_NoShowChartAndScheduled_CancelledChart.options}
                                    />
                                }
                            </CardBody>
                            <CardFooter>
                                <div className="legend">
                                    <i className="fa fa-circle text-info" /> Scheduled{" "}
                                    <i className="fa fa-circle text-danger" /> Cancelled{" "}
                                    <i className="fa fa-circle text-success" /> Show{" "}
                                    <i className="fa fa-circle text-warning" /> No-Show{" "}
                                </div>
                                <hr />
                                <div className="stats">
                                    {(city === "All") &&
                                        <>
                                            <i className="fa fa-calendar" />Number of <span className="badge badge-primary badge-pill">{gender}</span> patient appointments from all cities between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                                        </>
                                    }
                                    {(city !== "All") &&
                                        <>
                                            <i className="fa fa-calendar" />Number of <span className="badge badge-primary badge-pill">{gender}</span> patient appointments at <span className="badge badge-success badge-pill">{city}</span> between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                                        </>
                                    }
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" sm="6">
                        <Card className="card-stats">
                            <AppointmentSelector setAttendance1={setAttendance1} setAttendance2={setAttendance2}></AppointmentSelector>
                            <CardBody style={{ height: "25em" }}>
                                {(dates !== null) && 
                                    <>
                                        <Line
                                            data={TimeseriesAppointments.data(dates)}
                                            options={TimeseriesAppointments.options}
                                        />
                                    </>
                                }
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    {(city === "All") &&
                                        <>
                                            <i className="fa fa-calendar" />Number of <span className="badge badge-primary badge-pill">{gender}</span> patient appointments from all cities between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                                        </>
                                    }
                                    {(city !== "All") &&
                                        <>
                                            <i className="fa fa-calendar" />Number of <span className="badge badge-primary badge-pill">{gender}</span> patient appointments at <span className="badge badge-success badge-pill">{city}</span> between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                                        </>
                                    }
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody style={{ height: "28em" }}>
                                {(stats.unverified !== null & stats.verified !== null & dataTypes.Questionnaire !== null & dataTypes.Agents !== null) && 
                                    <Doughnut
                                        data={Show_NoShowChartAndScheduled_CancelledChart.data([stats.unverified, stats.verified], [dataTypes.Agents, dataTypes.Questionnaire])}
                                        options={Show_NoShowChartAndScheduled_CancelledChart.options}
                                    />
                                }
                            </CardBody>
                            <CardFooter>
                                <div className="legend">
                                    <i className="fa fa-circle text-info" /> Verified{" "}
                                    <i className="fa fa-circle text-danger" /> Unverified{" "}
                                    <i className="fa fa-circle text-success" /> Questionare{" "}
                                    <i className="fa fa-circle text-warning" /> Agent{" "}
                                </div>
                                <hr />
                                <div className="stats">
                                    {(city === "All") &&
                                        <>
                                            <i className="fa fa-calendar" />Number of <span className="badge badge-primary badge-pill">{gender}</span> patient appointments from all cities between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                                        </>
                                    }
                                    {(city !== "All") &&
                                        <>
                                            <i className="fa fa-calendar" />Number of <span className="badge badge-primary badge-pill">{gender}</span> patient appointments at <span className="badge badge-success badge-pill">{city}</span> between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                                        </>
                                    }
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Map height={390} defaultCenter={defaultCoords} defaultZoom={11}>
                                    {locations && locations.map((location, idx) => 
                                        <Marker 
                                            key={idx}
                                            width={50}
                                            onClick={() => setCity(location.city)}
                                            anchor={[location.lat, location.lon]}
                                            color={location.city === city && "#db4336"}
                                        />
                                    )}
                                    {locations && locations.map((location, idx) => 
                                        <Overlay key={idx} anchor={[location.lat, location.lon]} >
                                            <div className="map-overlay">
                                                <span className="city">{ location.city }</span>
                                                &nbsp;
                                                <span className="count">{ location.count }</span>
                                            </div>
                                        </Overlay>
                                    )}
                                </Map>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa-solid fa-location-dot" />Total <span className="badge badge-primary badge-pill">{gender}</span> patient appointments from all cities between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Gender;