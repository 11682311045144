import { 
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
} from "reactstrap";

import ForecastSelector from "components/Selector/ForecastSelector";

import "./Forecast.css";

function Forecast() {
    return (
        <>
            <div className="content">
                <Row>
                    <Col sm={12} md={12} lg={3}>
                        <Card style={{height: "100%"}}>
                            <CardHeader>
                                <CardTitle tag="h5">Predict Number Of Total Appointments</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <ForecastSelector type={0}></ForecastSelector>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="mobile-mt" sm={12} md={12} lg={3}>
                        <Card style={{height: "100%"}}>
                            <CardHeader>
                                <CardTitle tag="h5">Predict Number Of Total Appointments Per Clinic</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <ForecastSelector type={1}></ForecastSelector>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="mobile-mt" sm={12} md={12} lg={6}>
                        <Card style={{height: "100%"}}>
                            <CardHeader>
                                <CardTitle tag="h5">Predict Number Of Total No-Show Appointments</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <ForecastSelector type={2}></ForecastSelector>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>  
        </>
    );
}

export default Forecast;