import { useState } from "react";
import useFetch from "use-http";
import { Bar, Pie } from "react-chartjs-2";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import {
  Show_NoShowChart,
  Scheduled_CancelledChart,
  Verified_UnverifiedChart,
  ProvidingTypeChart,
  PerGenderChart,
  PerAgeChart,
} from "variables/charts.js";

import "./Dashboard.css";

import { defaultStartingDate, todayDateToString } from "variables/variables";

import Selector from "components/Selector/Selector"

const apiURL = process.env.REACT_APP_API_URL;

function Dashboard() {
  const [startDate, setStartDate] = useState(defaultStartingDate);
  const [endDate, setEndDate] = useState(todayDateToString);

  let dependencies = [startDate, endDate];

  const { data: stats = [] } = useFetch(`${apiURL}/stats/${startDate}/${endDate}/`, {}, dependencies);
  const { data: dataTypes = [] } = useFetch(`${apiURL}/get_data_type/${startDate}/${endDate}/`, {}, dependencies);
  const { data: genders = [] } = useFetch(`${apiURL}/get_satisfaction_gender/${startDate}/${endDate}/`, {}, dependencies);
  const { data: ages = [] } = useFetch(`${apiURL}/get_satisfaction_age/${startDate}/${endDate}/`, {}, dependencies);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12" md="12" sm="12">
            <Selector setStartDate={setStartDate} setEndDate={setEndDate} />
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-primary">
                      <i className="fa-solid fa-calendar-days text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Scheduled Appointments</p>
                        <CardTitle tag="p">{stats.scheduled}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> In Total
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa-solid fa-calendar-days text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Cancelled Appointments</p>
                        <CardTitle tag="p">{stats.cancelled}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> In Total
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-success">
                      <i className="fa-solid fa-calendar-days text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Show Appointments</p>
                        <CardTitle tag="p">{stats.show}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> In Total
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa-solid fa-calendar-days text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">No-Show Appointments</p>
                        <CardTitle tag="p">{stats.noshow}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> In Total
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Scheduled/Cancelled</CardTitle>
              </CardHeader>
              <CardBody style={{ height: "266px" }}>
                <Pie
                  data={Scheduled_CancelledChart.data([stats.scheduled, stats.cancelled])}
                  options={Scheduled_CancelledChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-primary" /> Scheduled{" "}
                  <i className="fa fa-circle text-danger" /> Cancelled{" "}
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" /> Appointments between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Show/No-Show</CardTitle>
              </CardHeader>
              <CardBody style={{ height: "266px" }}>
                <Pie
                  data={Show_NoShowChart.data([stats.show, stats.noshow])}
                  options={Show_NoShowChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-success" /> Show{" "}
                  <i className="fa fa-circle text-warning" /> No-Show{" "}
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" /> Appointments between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Verified/Unverified</CardTitle>
              </CardHeader>
              <CardBody style={{ height: "266px" }}>
                <Pie
                  data={Verified_UnverifiedChart.data([stats.verified, stats.unverified])}
                  options={Verified_UnverifiedChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-extra1" /> Verified{" "}
                  <i className="fa fa-circle text-extra2" /> Unverified{" "}
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" /> Appointments between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Data providing type</CardTitle>
              </CardHeader>
              <CardBody style={{ height: "266px" }}>
                <Pie
                  data={ProvidingTypeChart.data([dataTypes.Questionnaire, dataTypes.Agents])}
                  options={ProvidingTypeChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-extra3" /> Questionnare{" "}
                  <i className="fa fa-circle text-extra4" /> Agent{" "}
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" /> Appointments between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Satisfaction per gender</CardTitle>
              </CardHeader>
              <CardBody style={{ height: "266px" }}>
                <Bar
                  data={PerGenderChart.data([genders.female, genders.male, genders["n-a"]])}
                  options={PerGenderChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-info" /> Male{" "}
                  <i className="fa fa-circle text-danger" /> Female{" "}
                  <i className="fa fa-circle text-secondary" /> N-A{" "}
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-comment" /> Feedback comments between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Satisfaction per age</CardTitle>
              </CardHeader>
              <CardBody style={{ height: "266px" }}>
                <Bar
                  data={PerAgeChart.data([ages.Senior_Adult, ages.Middle_Age_Adult, ages.Adult, ages.Teen, ages.Child, ages.Toddler, ages.Infant])}
                  options={PerAgeChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-dark" /> Senior Adult{" "}
                  <i className="fa fa-circle text-secondary" /> Middle Age Adult{" "}
                  <i className="fa fa-circle text-primary" /> Adult{" "}
                  <i className="fa fa-circle text-info" /> Teen{" "}
                  <i className="fa fa-circle text-success" /> Child{" "}
                  <i className="fa fa-circle text-warning" /> Toddler{" "}
                  <i className="fa fa-circle text-danger" /> Infant{" "}
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-comment" /> Feedback comments between <span className="badge badge-info badge-pill">{startDate}</span> and <span className="badge badge-info badge-pill">{endDate}</span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
