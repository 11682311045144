import { useState } from "react";
import useFetch from "use-http";
import { Input, Row, Col, FormGroup, Label, Button } from "reactstrap";

import { todayDateToString, defaultClinicType } from "variables/variables";

import "./ForecastSelector.css";

const apiURL = process.env.REACT_APP_API_URL;

function ForecastSelector(props) {
    const [totalApptDate, setTotalApptDate] = useState(todayDateToString);

    const [totalApptClinicDate, setTotalApptClinicDate] = useState(todayDateToString);
    const [totalApptClinic, setTotalApptClinic] = useState(defaultClinicType);

    const [noshowApptDate, setNoshowApptDate] = useState(todayDateToString);    
    const [noshowApptVerified, setNoshowApptVerified] = useState(0);
    const [noshowApptSatisfaction, setNoshowApptSatisfaction] = useState(1);
    const [noshowApptType, setNoshowApptType] = useState("Questionaire");
    const [noshowApptGender, setNoshowApptGender] = useState("Male");
    const [noshowApptAge, setNoshowApptAge] = useState(25);
    const [noshowApptClinicType, setNoshowApptClinicType] = useState("Orthopedic");

    const handleTotalApptDateDateSelection = (e) => {
        e.preventDefault();
        setTotalApptDate(e.target.value);
    }

    const handleTotalApptClinicSelection = (e) => {
        e.preventDefault();
        setTotalApptClinic(e.target.value);
    }

    const handleTotalApptClinicDateDateSelection = (e) => {
        e.preventDefault();
        setTotalApptClinicDate(e.target.value);
    }

    const { get: getTotalAppointments, loading: totalAppointmentsLoading, data: totalAppointments = [] } = useFetch(`${apiURL}/predict_num_of_appointments/${totalApptDate}/`, {});
    const { get: getTotalAppointmentsClinic, loading: totalAppointmentsClinicLoading, data: totalAppointmentsClinic = [] } = useFetch(`${apiURL}/predict_num_of_appointments_per_clinic/${totalApptClinicDate}/${totalApptClinic}`, {});
    const { get: getNoshowTotal, loading: noshowTotalLoading, data: noshowTotal = [] } = useFetch(`${apiURL}/predict_no_show/${noshowApptVerified}/${noshowApptSatisfaction}/${noshowApptType}/${noshowApptGender}/${noshowApptAge}/${noshowApptClinicType}/${noshowApptDate}`, {});

    return (
        <>
            {props.type === 0 &&
                <div>
                    <Row>
                        <Col className="mt-3">
                            <FormGroup inline>
                                <Label>
                                    <b>Date:</b>
                                </Label>
                                <Input
                                    type="date"
                                    min={todayDateToString}
                                    onChange={handleTotalApptDateDateSelection}
                                    defaultValue={totalApptDate}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col className="text-center">
                            <Button className="btn-primary btn-round" onClick={() => getTotalAppointments()}>Predict</Button>
                            <div className="mt-3">
                                {totalAppointmentsLoading &&
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                }
                                {totalAppointments != "" && !totalAppointmentsLoading &&
                                    <h3><i className="text-primary fa-solid fa-calendar-days"></i>&nbsp;Prediction: {totalAppointments}</h3>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            {props.type === 1 &&
                 <div>
                    <Row>
                        <Col>
                            <FormGroup inline>
                                <Label>
                                    <b>Date:</b>
                                </Label>
                                <Input
                                    type="date"
                                    min={todayDateToString}
                                    onChange={handleTotalApptClinicDateDateSelection}
                                    defaultValue={totalApptDate}
                                />
                                <Label>
                                <b>Clinic Type:</b>
                                </Label>
                                <Input
                                    id="citySelect"
                                    name="select"
                                    type="select"
                                    onChange={handleTotalApptClinicSelection}
                                >
                                    <option>Orthopedic</option>
                                    <option>Pathological</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col className="text-center">
                            <Button className="btn-primary btn-round" onClick={() => getTotalAppointmentsClinic()}>Predict</Button>
                            <div className="mt-3">
                                {totalAppointmentsClinicLoading &&
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                }
                                {totalAppointmentsClinic != "" && !totalAppointmentsClinicLoading &&
                                    <h3><i className="text-primary fa-solid fa-house-medical"></i>&nbsp;Prediction: {totalAppointmentsClinic}</h3>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            {props.type === 2 &&
                <div>
                    <Row>
                        <Col>
                            <FormGroup inline>
                                <Label>
                                    <b>Date:</b>
                                </Label>
                                <Input
                                    type="date"
                                    min={todayDateToString}
                                    onChange={(e) => setNoshowApptDate(e.target.value)}
                                    defaultValue={totalApptDate}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup inline>
                                <Label>
                                    <b>Verified:</b>
                                </Label>
                                <Input
                                    type="select"
                                    onChange={(e) => setNoshowApptVerified(e.target.value)}
                                >
                                    <option>Yes</option>
                                    <option>No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup inline>
                                <Label>
                                    <b>Satisfaction:</b>
                                </Label>
                                <Input
                                    type="select"
                                    onChange={(e) => setNoshowApptSatisfaction(e.target.value)}
                                >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup inline>
                                <Label>
                                    <b>Data providing type:</b>
                                </Label>
                                <Input
                                    type="select"
                                    onChange={(e) => setNoshowApptType(e.target.value)}
                                >
                                    <option>Questionnare</option>
                                    <option>Agent</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup inline>
                                <Label>
                                    <b>Gender:</b>
                                </Label>
                                <Input
                                    type="select"
                                    onChange={(e) => setNoshowApptGender(e.target.value)}
                                >
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>N-A</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup inline>
                                <Label>
                                    <b>Age:</b>
                                </Label>
                                <Input
                                    type="number"
                                    value={noshowApptAge}
                                    onChange={(e) => setNoshowApptAge(e.target.value)}
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup inline>
                                <Label>
                                    <b>Clinic Type:</b>
                                </Label>
                                <Input
                                    type="select"
                                    onChange={(e) => setNoshowApptClinicType(e.target.value)}
                                >
                                    <option>Orthopedic</option>
                                    <option>Pathological</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col className="text-center">
                            <Button className="btn-primary btn-round" onClick={() => getNoshowTotal()}>Predict</Button>
                            <div className="mt-3">
                                {noshowTotalLoading &&
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                }
                                {noshowTotal != "" && !noshowTotalLoading &&   
                                    <h3><i className="text-primary fa-solid fa-calendar-check"></i>&nbsp;Prediction:&nbsp;{noshowTotal}</h3>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default ForecastSelector;