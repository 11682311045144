function About() {
    return (
        <>
            <div className="content">
                <span>This project has been developed in the framework of Clinapp Project</span>
            </div>  
        </>
    );
}

export default About;