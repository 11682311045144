const defaultCity = "All";
const defaultCoords = [37.9842, 23.7281];
const defaultClinicType = "Orthopedic";
const defaultGender = "Male";
const StartingDateCollectingData = "2010-03-02";

const today = new Date();
let todayDateToString = today.getFullYear().toString() +'-'+(today.getMonth()+1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");

const defaultStartingDate = "2022-02-25";

const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
}

export {StartingDateCollectingData, defaultStartingDate, defaultCity, defaultCoords, defaultClinicType, defaultGender, todayDateToString, roundToTwo}; 
