import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    Button
} from "reactstrap";

import { StartingDateCollectingData, defaultStartingDate, todayDateToString } from "../../variables/variables";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
  
function Selector(props) {
    const [selectorShow, setSelectorShow] = useState(true);
    const [buttonText, setButtonText] = useState("hide filter")
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleStartDateSelection = (e) => {
        e.preventDefault();
        props.setStartDate(e.target.value);
    }

    const handleEndDateSelection = (e) => {
        e.preventDefault();
        props.setEndDate(e.target.value);
    }

    const handleCitySelection = (e) => {
        e.preventDefault();
        props.setCity(e.target.value);
    }

    const handleClinicSelection = (e) => {
        e.preventDefault();
        props.setClinicType(e.target.value);
    }

    const handleGenderSelection = (e) => {
        e.preventDefault();
        props.setGender(e.target.value);
    }

    const handleShowClick = () => {
        if (selectorShow) {
            setSelectorShow(false);
            setButtonText("show filter")
        } else {
            setSelectorShow(true);
            setButtonText("hide filter")
        }
    }

    if (window.location.href.includes("/dashboard")) {
        return (
            <>  
                {(windowDimensions.width < 765) &&
                    <div className="container selector-body transparent-bg">
                        <Card className={selectorShow ? "selector-dark" : "transparent-bg"} style={{boxShadow: "none"}}>
                            <CardBody>
                                <Row>
                                    <Col sm={10} className={!selectorShow ? "full-opacity" : ""}>
                                        <FormGroup inline>
                                            <Label for="selectStartDate">
                                                <b>From:</b>
                                            </Label>
                                            <Input
                                                disabled={selectorShow ? false : true}
                                                id="selectStartDate"
                                                name="start-date"
                                                placeholder="date placeholder"
                                                type="date"
                                                min={StartingDateCollectingData}
                                                max={todayDateToString}
                                                onChange={handleStartDateSelection}
                                                defaultValue={defaultStartingDate}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={10} className={!selectorShow ? "full-opacity" : ""}>
                                        <FormGroup inline>
                                            <Label for="selectEndDate">
                                                <b>To:</b>
                                            </Label>
                                            <Input
                                                disabled={selectorShow ? false : true}
                                                id="selectEndDate"
                                                name="end-date"
                                                placeholder="date placeholder"
                                                type="date"
                                                min={StartingDateCollectingData}
                                                max={todayDateToString}
                                                onChange={handleEndDateSelection}
                                                defaultValue={todayDateToString}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={2} md={1}>
                                        <Button
                                            block
                                            className="show-btn mt-2 h-75 bg-dark"
                                            onClick={() => handleShowClick()}
                                        >{buttonText}</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                }
                {(windowDimensions.width > 765) &&
                    <div>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup inline>
                                            <Label for="selectStartDate">
                                                <b>From:</b>
                                            </Label>
                                            <Input
                                                id="selectStartDate"
                                                name="start-date"
                                                placeholder="date placeholder"
                                                type="date"
                                                min={StartingDateCollectingData}
                                                max={todayDateToString}
                                                onChange={handleStartDateSelection}
                                                defaultValue={defaultStartingDate}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col >
                                        <FormGroup inline>
                                            <Label for="selectEndDate">
                                                <b>To:</b>
                                            </Label>
                                            <Input
                                                id="selectEndDate"
                                                name="end-date"
                                                placeholder="date placeholder"
                                                type="date"
                                                min={StartingDateCollectingData}
                                                max={todayDateToString}
                                                onChange={handleEndDateSelection}
                                                defaultValue={todayDateToString}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                }
            </>
        );
    } else if (window.location.href.includes("/clinics")) {
        return (
            <>
                {(windowDimensions.width < 765) &&
                    <div className="container selector-body transparent-bg">
                        <Card className={selectorShow ? "selector-dark" : "transparent-bg"} style={{boxShadow: "none"}}>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col xs={6} className={!selectorShow ? "full-opacity" : ""}>
                                            <FormGroup inline>
                                                <Label for="selectStartDate">
                                                    <b>From:</b>
                                                </Label>
                                                <Input
                                                    disabled={selectorShow ? false : true}
                                                    id="selectStartDate"
                                                    name="start-date"
                                                    placeholder="date placeholder"
                                                    type="date"
                                                    min={StartingDateCollectingData}
                                                    max={todayDateToString}
                                                    onChange={handleStartDateSelection}
                                                    defaultValue={defaultStartingDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} className={!selectorShow ? "full-opacity" : ""}>
                                            <FormGroup inline>
                                                <Label for="selectEndDate">
                                                    <b>To:</b>
                                                </Label>
                                                <Input
                                                    disabled={selectorShow ? false : true}
                                                    id="selectEndDate"
                                                    name="end-date"
                                                    placeholder="date placeholder"
                                                    type="date"
                                                    min={StartingDateCollectingData}
                                                    max={todayDateToString}
                                                    onChange={handleEndDateSelection}
                                                    defaultValue={todayDateToString}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} className={!selectorShow ? "full-opacity" : ""}>
                                            <FormGroup inline>
                                                <Label for="selectEndDate">
                                                    <b>City:</b>
                                                </Label>
                                                <Input
                                                    disabled={selectorShow ? false : true}
                                                    id="citySelect"
                                                    name="select"
                                                    type="select"
                                                    value={props.city}
                                                    onChange={handleCitySelection}
                                                >
                                                    <option>All</option>
                                                    <option>Athens</option>
                                                    <option>Thessaloníki</option>
                                                    <option>Pátra</option>
                                                    <option>Piraeus</option>
                                                    <option>Lárisa</option>
                                                    <option>Irákleio</option>
                                                    <option>Peristéri</option>
                                                    <option>Kallithéa</option>
                                                    <option>Níkaia</option>
                                                    <option>Glyfáda</option>
                                                    <option>Vólos</option>
                                                    <option>Chaniá</option>
                                                    <option>Chalándri</option>
                                                    <option>Kalamáta</option>
                                                    <option>Néa Ionía</option>
                                                    <option>Ioánnina</option>
                                                    <option>Palaió Fáliro</option>
                                                    <option>Tríkala</option>
                                                    <option>Výronas</option>
                                                    <option>Agía Paraskeví</option>
                                                    <option>Galátsi</option>
                                                    <option>Kavála</option>
                                                    <option>Chalkída</option>
                                                    <option>Sérres</option>
                                                    <option>Alexandroúpoli</option>
                                                    <option>Ródos</option>
                                                    <option>Xánthi</option>
                                                    <option>Kateríni</option>
                                                    <option>Lamía</option>
                                                    <option>Komotiní</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} className={!selectorShow ? "full-opacity" : ""}>
                                            <FormGroup inline>
                                                <Label for="selectEndDate">
                                                    <b>Clinic type:</b>
                                                </Label>
                                                <Input
                                                    disabled={selectorShow ? false : true}
                                                    id="citySelect"
                                                    name="select"
                                                    type="select"
                                                    onChange={handleClinicSelection}
                                                >
                                                    <option>Orthopedic</option>
                                                    <option>Pathological</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={1}>
                                            <Button
                                                block
                                                className="show-btn mt-2 h-75 bg-dark"
                                                onClick={() => handleShowClick()}
                                            >{buttonText}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                }
                {(windowDimensions.width > 765) &&
                    <Card>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup inline>
                                            <Label for="selectStartDate">
                                                <b>From:</b>
                                            </Label>
                                            <Input
                                                id="selectStartDate"
                                                name="start-date"
                                                placeholder="date placeholder"
                                                type="date"
                                                min={StartingDateCollectingData}
                                                max={todayDateToString}
                                                onChange={handleStartDateSelection}
                                                defaultValue={defaultStartingDate}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup inline>
                                            <Label for="selectEndDate">
                                                <b>To:</b>
                                            </Label>
                                            <Input
                                                id="selectEndDate"
                                                name="end-date"
                                                placeholder="date placeholder"
                                                type="date"
                                                min={StartingDateCollectingData}
                                                max={todayDateToString}
                                                onChange={handleEndDateSelection}
                                                defaultValue={todayDateToString}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup inline>
                                            <Label for="selectEndDate">
                                                <b>City:</b>
                                            </Label>
                                            <Input
                                                id="citySelect"
                                                name="select"
                                                type="select"
                                                value={props.city}
                                                onChange={handleCitySelection}
                                            >
                                                <option>All</option>
                                                <option>Athens</option>
                                                <option>Thessaloníki</option>
                                                <option>Pátra</option>
                                                <option>Piraeus</option>
                                                <option>Lárisa</option>
                                                <option>Irákleio</option>
                                                <option>Peristéri</option>
                                                <option>Kallithéa</option>
                                                <option>Níkaia</option>
                                                <option>Glyfáda</option>
                                                <option>Vólos</option>
                                                <option>Chaniá</option>
                                                <option>Chalándri</option>
                                                <option>Kalamáta</option>
                                                <option>Néa Ionía</option>
                                                <option>Ioánnina</option>
                                                <option>Palaió Fáliro</option>
                                                <option>Tríkala</option>
                                                <option>Výronas</option>
                                                <option>Agía Paraskeví</option>
                                                <option>Galátsi</option>
                                                <option>Kavála</option>
                                                <option>Chalkída</option>
                                                <option>Sérres</option>
                                                <option>Alexandroúpoli</option>
                                                <option>Ródos</option>
                                                <option>Xánthi</option>
                                                <option>Kateríni</option>
                                                <option>Lamía</option>
                                                <option>Komotiní</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup inline>
                                            <Label for="selectEndDate">
                                                <b>Clinic type:</b>
                                            </Label>
                                            <Input
                                                id="citySelect"
                                                name="select"
                                                type="select"
                                                onChange={handleClinicSelection}
                                            >
                                                <option>Orthopedic</option>
                                                <option>Pathological</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                }
            </>
        );
    } else if (window.location.href.includes("/gender")) {
        return (
            <>
                {(windowDimensions.width < 765) &&
                    <div className="container selector-body transparent-bg">
                        <Card className={selectorShow ? "selector-dark" : "transparent-bg"} style={{boxShadow: "none"}}>
                            <CardBody>
                                <Form>
                                    <Row form>
                                        <Col xs={6} className={!selectorShow ? "full-opacity" : ""}>
                                            <FormGroup inline>
                                                <Label for="selectStartDate">
                                                    <b>From:</b>
                                                </Label>
                                                <Input
                                                    id="selectStartDate"
                                                    name="start-date"
                                                    placeholder="date placeholder"
                                                    type="date"
                                                    min={StartingDateCollectingData}
                                                    max={todayDateToString}
                                                    onChange={handleStartDateSelection}
                                                    defaultValue={defaultStartingDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} className={!selectorShow ? "full-opacity" : ""}>
                                            <FormGroup inline>
                                                <Label for="selectEndDate">
                                                    <b>To:</b>
                                                </Label>
                                                <Input
                                                    id="selectEndDate"
                                                    name="end-date"
                                                    placeholder="date placeholder"
                                                    type="date"
                                                    min={StartingDateCollectingData}
                                                    max={todayDateToString}
                                                    onChange={handleEndDateSelection}
                                                    defaultValue={todayDateToString}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} className={!selectorShow ? "full-opacity" : ""}>
                                            <FormGroup inline>
                                                <Label for="selectEndDate">
                                                    <b>City:</b>
                                                </Label>
                                                <Input
                                                    id="citySelect"
                                                    name="select"
                                                    type="select"
                                                    value={props.city}
                                                    onChange={handleCitySelection}
                                                >
                                                    <option>All</option>
                                                    <option>Athens</option>
                                                    <option>Thessaloníki</option>
                                                    <option>Pátra</option>
                                                    <option>Piraeus</option>
                                                    <option>Lárisa</option>
                                                    <option>Irákleio</option>
                                                    <option>Peristéri</option>
                                                    <option>Kallithéa</option>
                                                    <option>Níkaia</option>
                                                    <option>Glyfáda</option>
                                                    <option>Vólos</option>
                                                    <option>Chaniá</option>
                                                    <option>Chalándri</option>
                                                    <option>Kalamáta</option>
                                                    <option>Néa Ionía</option>
                                                    <option>Ioánnina</option>
                                                    <option>Palaió Fáliro</option>
                                                    <option>Tríkala</option>
                                                    <option>Výronas</option>
                                                    <option>Agía Paraskeví</option>
                                                    <option>Galátsi</option>
                                                    <option>Kavála</option>
                                                    <option>Chalkída</option>
                                                    <option>Sérres</option>
                                                    <option>Alexandroúpoli</option>
                                                    <option>Ródos</option>
                                                    <option>Xánthi</option>
                                                    <option>Kateríni</option>
                                                    <option>Lamía</option>
                                                    <option>Komotiní</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} className={!selectorShow ? "full-opacity" : ""}>
                                            <FormGroup inline>
                                                <Label for="selectEndDate">
                                                    <b>Gender:</b>
                                                </Label>
                                                <Input
                                                    name="select"
                                                    type="select"
                                                    onChange={handleGenderSelection}
                                                >
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                    <option>N-A</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={1}>
                                            <Button
                                                block
                                                className="show-btn mt-2 h-75 bg-dark"
                                                onClick={() => handleShowClick()}
                                            >{buttonText}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                }
                {(windowDimensions.width > 765) &&
                    <Card>
                        <CardBody>
                            <Form>
                                <Row form>
                                    <Col s={6}>
                                        <FormGroup inline>
                                            <Label for="selectStartDate">
                                                <b>From:</b>
                                            </Label>
                                            <Input
                                                id="selectStartDate"
                                                name="start-date"
                                                placeholder="date placeholder"
                                                type="date"
                                                min={StartingDateCollectingData}
                                                max={todayDateToString}
                                                onChange={handleStartDateSelection}
                                                defaultValue={defaultStartingDate}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col s={6}>
                                        <FormGroup inline>
                                            <Label for="selectEndDate">
                                                <b>To:</b>
                                            </Label>
                                            <Input
                                                id="selectEndDate"
                                                name="end-date"
                                                placeholder="date placeholder"
                                                type="date"
                                                min={StartingDateCollectingData}
                                                max={todayDateToString}
                                                onChange={handleEndDateSelection}
                                                defaultValue={todayDateToString}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col s={6}>
                                        <FormGroup inline>
                                            <Label for="selectEndDate">
                                                <b>City:</b>
                                            </Label>
                                            <Input
                                                id="citySelect"
                                                name="select"
                                                type="select"
                                                value={props.city}
                                                onChange={handleCitySelection}
                                            >
                                                <option>All</option>
                                                <option>Athens</option>
                                                <option>Thessaloníki</option>
                                                <option>Pátra</option>
                                                <option>Piraeus</option>
                                                <option>Lárisa</option>
                                                <option>Irákleio</option>
                                                <option>Peristéri</option>
                                                <option>Kallithéa</option>
                                                <option>Níkaia</option>
                                                <option>Glyfáda</option>
                                                <option>Vólos</option>
                                                <option>Chaniá</option>
                                                <option>Chalándri</option>
                                                <option>Kalamáta</option>
                                                <option>Néa Ionía</option>
                                                <option>Ioánnina</option>
                                                <option>Palaió Fáliro</option>
                                                <option>Tríkala</option>
                                                <option>Výronas</option>
                                                <option>Agía Paraskeví</option>
                                                <option>Galátsi</option>
                                                <option>Kavála</option>
                                                <option>Chalkída</option>
                                                <option>Sérres</option>
                                                <option>Alexandroúpoli</option>
                                                <option>Ródos</option>
                                                <option>Xánthi</option>
                                                <option>Kateríni</option>
                                                <option>Lamía</option>
                                                <option>Komotiní</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col s={6}>
                                        <FormGroup inline>
                                            <Label for="selectEndDate">
                                                <b>Gender:</b>
                                            </Label>
                                            <Input
                                                name="select"
                                                type="select"
                                                onChange={handleGenderSelection}
                                            >
                                                <option>Male</option>
                                                <option>Female</option>
                                                <option>N-A</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                }
            </>
        )
    } else if (window.location.href.includes("/forecast")) {

    }
};

export default Selector;